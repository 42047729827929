
const jobs = [
    {
        title: "Software Engineer",
        address: " Axcer",
        hours: "Posted 1 week ago",
        time: "Full-time",
        id: "1",
        tagId: ["all", "development"],
        url: "/careers/software-engineer",
        about: "The ideal candidate will be responsible for developing high-quality applications. They will also be responsible for designing and implementing mobile application",
        responsibilities: [
            "Develop quality software and web applications.",
            "Analyze and maintain existing software applications.",
            "Design highly scalable, testable code.",
            "Discover and fix programming bugs.",
            "Hands on experience in React Native Mobile development or Expo.",
            "Proficient with TypeScript, ES5/ES6, State management libraries such as Redux/ Context API, React Hooks, JSX and CSS-in-JS.",
            "Experience of working with SQL Databases (PostgreSQL/MySQL) or NoSQL databases (MongoDB, DynomoDB, Redis, etc.)",
            "Excellent understanding of at least one backend framework (Express, NestJS or Java spring)",
            "Proficient in using front end frameworks such as React js, React Native or Vue.js.",
            "Experience with designing application architectures and concepts like unit testing, TDD and continuous integration.",
        ],
        qualification: [
            "Bachelor's degree or equivalent experience in Computer Science or related field.",
            "Development experience with programming languages ( Node.js, Java, Python )."
        ],
        goodtohave: [
            "Experience of working with REST APIs, Graph SQL with AWS, Azure or Google cloud.",
            "Knowledge in Data Structures, Algorithms.",
            "Previous experience in Adobe XD or any othe UI/UX design tool."
        ],
        offers: [
            "An attractive rumination package with a large space to grow up."
        ],
        apply: "Send your CV to careers@axcer.io.",
        applyurl: "https://www.linkedin.com/company/axcer/jobs/"


    },

    // {
    //     title:"React Developer",
    //     address:" South Haven, USA",
    //     hours:"Posted 6 hours ago",
    //     time:"Full-time",
    //     salary:"9,000 USD",
    //     id:"2",
    //     tagId: ["all","development"]
    // },
    // {
    //     title:"Wordpress Developer",
    //     address:" South Haven, USA",
    //     hours:"Posted 8 hours ago",
    //     time:"Full-time",
    //     salary:"5,000 Eur",
    //     id:"3",
    //     tagId: ["all","development","marketing"]
    // },
    // {
    //     title:"Product Designer",
    //     address:" South Haven, USA",
    //     hours:"Posted 10 hours ago",
    //     time:"Full-time",
    //     salary:"9,000 Rupee",
    //     id:"4",
    //     tagId: ["all","design"]
    // },
    // {
    //     title:"Customer Support",
    //     address:" South Haven, USA",
    //     hours:"Posted 20 hours ago",
    //     time:"Full-time",
    //     salary:"91,000 USD",
    //     id:"5",
    //     tagId: ["all","development","research"]
    // },
    // {
    //     title:"Marketing Manager",
    //     address:" South Haven, USA",
    //     hours:"Posted 5 hours ago",
    //     time:"Full-time",
    //     salary:"91,000 EUR",
    //     id:"6",
    //     tagId: ["all","support"]
    // }
]


export default jobs;