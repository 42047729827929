
import React from 'react'
import { Link } from '~components'
import Card from './style'
export default function CareerCard({ title, address, hours, time, to, ...rest }) {
  return (
    <Card>
      <Card.Box>
        <Card.Title fontColor="#262729">{title}</Card.Title>
        <Card.List>
          <Card.ListItem><Link to='/href'><i className="fa fa-map-marker-alt text-electric-violet-2" /> {address}</Link></Card.ListItem>
          <Card.ListItem><Link to='/href'><i className="fa fa-briefcase text-electric-violet-2" /> {time}</Link></Card.ListItem>
          <Card.ListItem><Link to='/href'><i className="fa fa-clock text-electric-violet-2" /> {hours}</Link></Card.ListItem>
        </Card.List>
      </Card.Box>
      <Card.ButtonGroup>
        <Card.Button mb="10px" className="btn btn-outline-primary hover-white" active={true} as={Link} to={to ? to : "/"}>Apply</Card.Button>
        <Card.Button className="btn btn-outline-primary hover-white" as={Link} to="/">Share</Card.Button>
      </Card.ButtonGroup>

    </Card>
  )
}