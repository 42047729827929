import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from '~components';
import CareerCard from './Components/Card'
import Career from './style'
import job from '~sections/Careers/Job/data.js';
export default function CareerDetailsSection() {
  return (
    <Career backgroundColor="#f3f4f6">

      {job.map((function (jobDetails) {
        if (jobDetails.id == 1) {
          return (

            <Container key={jobDetails.id}>
              <Row className="justify-content-center">
                <Col className="col-xl-8 col-lg-10">
                  <CareerCard

                    title={jobDetails.title}
                    address={jobDetails.address}
                    hours={jobDetails.hours}
                    time={jobDetails.time}
                    to={jobDetails.applyurl}

                  />
                </Col>
              </Row>

              <Row className="row justify-content-center">
                <Col className="col-xl-8 col-lg-10">
                  <Career.Wrapper>

                    {/* About the job */}
                    {jobDetails.about ? <Career.ContentBlock>
                      <Career.Title fontColor="#262729">About the job</Career.Title>
                      <Career.Text>{jobDetails.about}</Career.Text>
                    </Career.ContentBlock> : ""}

                    {/* Responsibilities */}
                    {jobDetails.responsibilities ?
                      <Career.ContentBlock>
                        <Career.Title fontColor="#262729">Responsibilities</Career.Title>
                        <Career.List>
                          {
                            jobDetails.responsibilities.map((item, index) =>
                              <Career.ListItem key={index}>{item}</Career.ListItem>
                            )}
                        </Career.List>
                      </Career.ContentBlock> : ""}

                    {/* Skills & Qualification */}
                    {jobDetails.qualification ? <Career.ContentBlock>
                      <Career.Title fontColor="#262729">Skills &amp; Qualification</Career.Title>
                      <Career.List>
                        {jobDetails.qualification.map((item, index) =>
                          <Career.ListItem key={index}>{item}</Career.ListItem>
                        )}
                      </Career.List>
                    </Career.ContentBlock> : ""}


                    {/* Good To Have */}
                    {jobDetails.goodtohave ?
                      <Career.ContentBlock>
                        <Career.Title fontColor="#262729">Good To Have</Career.Title>
                        <Career.List>
                          {jobDetails.goodtohave.map((item, index) =>
                            <Career.ListItem key={index}>{item}</Career.ListItem>
                          )}
                        </Career.List>
                      </Career.ContentBlock> : ""}


                    {/* What we offer */}
                    {jobDetails.offers ? <Career.ContentBlock>
                      <Career.Title fontColor="#262729">What we offer</Career.Title>
                      <Career.List>
                        {jobDetails.offers.map((item, index) =>
                          <Career.ListItem key={index}>{item}</Career.ListItem>
                        )}
                      </Career.List>
                    </Career.ContentBlock> : ""}

                    <Career.ContentBlock>
                      <Career.Title fontColor="#262729">How to apply</Career.Title>
                      <Career.Text mb="30px">{jobDetails.apply}</Career.Text>
                      <Career.Button to={jobDetails.applyurl ? jobDetails.applyurl : '/'}>Apply for this position</Career.Button>
                    </Career.ContentBlock>



                  </Career.Wrapper>
                </Col>
              </Row>

            </Container>


          )



        }
      }))
      }



    </Career>

  )
}